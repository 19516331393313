<template>
  <v-card outlined elevation="0" class="d-flex flex-column" min-height="80vh">
    <v-card-title class="text-h5 font-weight-medium">
      <v-btn class="px-n3" color="#122333" @click="back">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <div class="ml-5">Create New Assessment</div>
    </v-card-title>
    <v-stepper v-model="pos" elevation="0">
      <v-stepper-header>
        <v-stepper-step :complete="pos > 1" step="1">
          Basic Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="pos > 2" step="2">
          Select Skills
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="pos > 3" step="3"> Settings </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Final Preview </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- basic details -->
        <v-stepper-content step="1">
          <v-form
            v-model="basicStep"
            class="basic pa-5"
            ref="basicDetails"
            lazy-validation
          >
            <v-row>
              <v-col cols="6">
                <label for="testname" class="font-weight-bold text-body-1">
                  Assessment Name
                </label>
                <v-text-field
                  v-model="testname"
                  id="testname"
                  outlined
                  placeholder="Name your assessment"
                  :rules="[(v) => !!v || 'Required']"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="jobrole" class="font-weight-bold text-body-1">
                  Role
                </label>
                <v-text-field
                  v-model="role"
                  id="jobrole"
                  outlined
                  placeholder="Enter Job Role"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <label for="desc" class="font-weight-bold text-body-1">
              Description
            </label>
            <v-textarea
              id="desc"
              v-model="description"
              outlined
              rows="8"
              placeholder="Enter description about assessment and/or job role"
            >
            </v-textarea>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <skills
            :skills="skills"
            :skillTags="skillTags"
            :hiringPlan="hiringPlan"
            @addskill="(skillA) => addSkillToTest(skillA)"
            @removeskill="(skillR) => removeSkillFromTest(skillR)"
          ></skills>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12" md="6">
              <v-card class="pa-5" elevation="0">
                <v-card-title> Anti-Cheat Settings </v-card-title>
                <v-card-text>
                  <v-checkbox
                    disabled
                    v-model="antiCheat"
                    :value="1"
                    label="Disallow External Copy Paste"
                  >
                  </v-checkbox>
                  <v-checkbox
                    disabled
                    v-model="antiCheat"
                    :value="2"
                    label="Make Fullscreen"
                  >
                  </v-checkbox>
                  <v-checkbox
                    disabled
                    v-model="antiCheat"
                    :value="3"
                    label="Log Tab Change Events"
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-model="antiCheat"
                    :value="5"
                    label="Take Video Recording"
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="0" class="pa-5">
                <v-card-title> Test Settings </v-card-title>
                <v-card-text>
                  <v-checkbox
                    v-model="testByUrlEnabled"
                    label="Enable Test Invite by URL"
                  >
                  </v-checkbox>
                  <div v-if="testByUrlEnabled">
                    <label for="url-expiry-picker" class="text-h6 black--text">
                      Set Expiry Date for Test URL
                    </label>
                    <div>
                      <v-date-picker
                        id="url-expiry-picker"
                        :allowed-dates="allowFutureDates"
                        v-model="testUrlExpiryEpoch"
                        elevation="1"
                      >
                      </v-date-picker>
                    </div>
                  </div>

                  <div>
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="setMinScore"
                          label="Set minimum score for assessment"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row v-if="setMinScore">
                      <v-col cols="5">
                        <v-text-field
                          v-model="minScore"
                          label="Minimum score"
                          type="number"
                          :rules="[minScoreRule]"
                        ></v-text-field>
                        <v-checkbox
                          v-model="sendEmail"
                          label="Send Email?"
                        ></v-checkbox>
                        <v-alert
                          v-if="setMinScore"
                          type="warning"
                          dense
                          outlined
                        >
                          {{ autoRejectMessage }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-row>
                      <v-col> Test Deadline (from invite time) </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="3"
                        v-for="unit in timeUnits"
                        :key="unit.label"
                      >
                        <v-text-field
                          v-model="unit.value"
                          :label="unit.label"
                          type="number"
                          :placeholder="`Enter ${unit.label}`"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                text
                class="text-none text-body-1"
                id="add-test-url"
                @click="openStartTime"
              >
                <v-icon left color="primary">
                  {{ mdiLink }}
                </v-icon>
                Add Test Start Time
              </v-btn>
              <time-select ref="startTime" v-model="startEpoch"></time-select>
              <v-btn
                color="primary"
                text
                class="text-none text-body-1"
                id="add-test-url"
                @click="openEndTime"
              >
                <v-icon left color="primary">
                  {{ mdiLink }}
                </v-icon>
                Add Test End Time
              </v-btn>
              <time-select ref="endTime" v-model="endEpoch"></time-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert v-if="startEpoch" type="success" dense outlined>
                Test Start Time: {{ formatDate(startEpoch) }}
              </v-alert>
            </v-col>
            <v-col>
              <v-alert v-if="endEpoch" type="success" dense outlined>
                Test End Time: {{ formatDate(endEpoch) }}
              </v-alert>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card elevation="0">
            <v-card-title>
              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="6">
                  <b>Test Name:</b> {{ testname }}
                </v-col>
                <v-col cols="12" md="6">
                  <b>Role:</b> {{ role ?? 'Not set' }}
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="text-body-1 px-8 black--text">
              <v-row>
                <v-col cols="12" md="6">
                  <div class="text-h6 pt-5 font-weight-bold">Test Skills</div>
                  <ol>
                    <li v-for="sk in descTasks" :key="sk.id">
                      {{ sk.title }}
                    </li>
                  </ol>
                  <div class="text-h6 pt-5">
                    <b>Total Test Duration: </b> {{ displayTime(totalTimeInS) }}
                  </div>

                  <div class="text-h6 pt-5">
                    <b>Anti-cheat settings: </b>
                  </div>
                  <ul class="text-body-1">
                    <li v-if="extCopy">Disallow External Copy Paste</li>
                    <li v-if="tabLog">Log Tab Change Events</li>
                    <li v-if="makeFullscreen">Make Fullscreen</li>
                    <li v-if="videoRecording">Take Video Recordings</li>
                  </ul>
                </v-col>
                <v-col cols="12" md="6" class="d-flex flex-column text-h6">
                  <div class="d-flex flex-row">
                    <b>Enable Test URL:</b>
                    <div class="pl-3">
                      {{ testByUrlEnabled ? 'Yes' : 'No' }}
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <b v-if="testByUrlEnabled">Test URL Expiry:</b>
                    <div class="pl-3">
                      {{
                        testByUrlEnabled
                          ? new Date(testUrlExpiryEpoch).toDateString()
                          : ''
                      }}
                    </div>
                  </div>
                </v-col>
                <v-col class="d-flex flex-column text-h6">
                  <div class="d-flex flex-row">
                    <b>Test Deadline:</b>
                    <div class="pl-3">
                      <span v-if="hasDeadline">
                        <span v-for="unit in timeUnits" :key="unit.label">
                          {{ unit.value }} {{ unit.label }}
                        </span>
                      </span>
                      <span v-else> None </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-spacer></v-spacer>
    <v-card-actions class="justify-space-between pb-16">
      <v-btn
        class="px-5 py-2 text-none"
        color="gray"
        outlined
        @click="back"
        :disabled="pos === 1"
      >
        Back
      </v-btn>
      <v-btn
        @click="next"
        color="success"
        class="text-none text-subtitle-1 px-5 py-2"
        :disabled="
          (pos === 2 && !(skillIds.size > 0 && skillIds.size <= 5)) ||
          (hiringPlan == 'Free' &&
            antiCheat.includes(
              this.cheatingSettingsValues['ANTI_CHEATING_VIDEO_RECORDING'],
            ))
        "
        :loading="submitted"
      >
        {{ pos === 4 ? 'Finish' : 'Next' }}
      </v-btn>
    </v-card-actions>
    <div>
      <go-premium ref="unlock" imageUrl="images/GoPremium.png"> </go-premium>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  mdiPlus,
  mdiMinus,
  mdiTimer,
  mdiInformation,
  mdiArrowLeft,
} from '@mdi/js'
import { mdiLockOutline, mdiLink } from '@mdi/js'
import SkillModal from '../../components/recruiter/SkillModal.vue'
import GoPremium from '../../components/assessment/GoPremium.vue'
import formattedTimeMixin from '@/mixins/formattedTimeMixin'
import moment from 'moment'
import TimeSelect from './TimeSelect.vue'
import { allowFutureDates, formatDate } from '@/utils/helper.js'
import Skills from './Skills.vue'

export default {
  components: { SkillModal, GoPremium, TimeSelect, Skills },
  data() {
    return {
      skillSearch: '',
      pos: 1,
      testname: '',
      description: '',
      role: null,
      skills: [],
      skillIds: new Set(),
      skillTags: [],
      descTasks: [],
      // needs to be uniq
      basicStep: true,
      mdiPlus,
      mdiMinus,
      mdiTimer,
      mdiInformation,
      mdiArrowLeft,
      antiCheat: [1, 2, 3],
      submitted: false,
      skillDlg: [],
      viewingSkill: null,
      totalTimeInS: 0,
      mdiLockOutline,
      mdiLink,
      testByUrlEnabled: false,
      testUrlExpiryEpoch: moment().add(7, 'days').format('YYYY-MM-DD'),
      expiryTime: null,
      setMinScore: false,
      minScore: 0,
      sendEmail: false,
      startEpoch: 0,
      endEpoch: 0,
      timeUnits: [
        { label: 'Days', value: 0, multiplier: 86400 },
        { label: 'Hours', value: 0, multiplier: 3600 },
        { label: 'Minutes', value: 0, multiplier: 60 },
        { label: 'Seconds', value: 0, multiplier: 1 },
      ],
    }
  },
  props: {
    jobId: {
      default: String,
      required: false,
    },
  },
  mixins: [formattedTimeMixin],
  methods: {
    ...mapActions('recruiter', [
      'listSkills',
      'fetchSkill',
      'createAssessment',
      'updateAssessment',
    ]),
    ...mapActions('jobs', ['linkJobWithTest']),
    allowFutureDates: allowFutureDates,
    formatDate: formatDate,
    openStartTime() {
      this.$refs.startTime.openDialog()
    },
    openEndTime() {
      this.$refs.endTime.openDialog()
    },
    rmSkillFromTagList(item) {
      this.skillTags = [...this.skillTags.filter((sk) => sk.id !== item.id)]
      console.log(item.id, this.skillIds)
      this.removeSkillFromTest(item)
    },
    closeSkillModal(index) {
      this.$set(this.skillDlg, index, false)
    },
    submit() {
      this.submitted = true
      this.createAssessment({ title: this.testname }).then((res) => {
        console.log('start EPOCH', this.startEpoch)
        console.log('end EPOCH', this.endEpoch)
        this.expiryTime = moment().format('HH:mm')
        var epoch = this.calculateDatetime(
          this.testUrlExpiryEpoch,
          this.expiryTime,
        )
        const deadline = this.calculateDeadline()
        this.updateAssessment({
          about: this.description,
          role: this.role,
          skills: this.skillIds,
          anticheat: this.antiCheat,
          enableLink: this.testByUrlEnabled,
          expiryEpoch: epoch,
          setMinScore: this.setMinScore,
          minScore: this.minScore,
          sendEmail: this.sendEmail,
          windowStartEpoch: this.startEpoch,
          windowEndEpoch: this.endEpoch,
          deadline: deadline,
        })
          .then((r) => {
            // console.log(this.createdAssessment.toObject());
            if (this.jobId) {
              console.log('Res ..', res)
              this.linkJobWithTest({
                test_id: res.getContent().getId(),
                job_id: this.jobId,
              })
                .then(() => {
                  this.$store.dispatch('notifs/addNotif', {
                    text: 'Successfully linked test with Job!',
                    type: 'success',
                  })
                  this.linking = false
                })
                .catch(() => {
                  this.linking = false
                  this.$store.dispatch('notifs/addNotif', {
                    text: 'Error occured while linking test with Job, please try again later!',
                    type: 'error',
                  })
                })
            }
            this.$router.push(`/assessments/${this.createdAssessment.getUrl()}`)
          })
          .catch((e) => {
            console.error(e)
          })
      })
    },
    calculateDatetime(date, time) {
      if (time && date) {
        var datetime = date + ' ' + time
        return moment(datetime, 'YYYY-MM-DD H:mm').unix()
      }
    },
    calculateDeadline() {
      var deadline = 0
      this.timeUnits.forEach((unit) => {
        deadline += unit.value * unit.multiplier
      })
      return deadline
    },
    back() {
      if (this.pos !== 1) {
        this.pos = this.pos - 1
      } else {
        this.$router.push('/assessments')
      }
    },
    next() {
      if (this.pos === 1) {
        if (this.$refs.basicDetails.validate()) {
          this.pos = 2
        }
      } else if (this.pos === 2 || this.pos === 3) {
        this.pos += 1
      } else if (this.pos === 4) {
        // console.log(`submit stuff`);
        this.submit()
      } else {
        // console.log("out of bounds");
      }
    },
    addSkillToTest(skill) {
      console.log('Got event addSkillToTest ....', skill)
      if (this.skillIds.size + 1 > 5) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'You can only add upto 5 skills!',
          type: 'error',
        })
        return
      }
      this.skillIds.add(skill.id)
      this.skillIds = new Set(this.skillIds.values())
      // this.skillIds.push(skill.id);
      this.$set(skill, 'added', true)
      // console.log(this.skillIds, skill);
    },
    removeSkillFromTest(skill) {
      this.skillIds.delete(skill.id)
      this.skillIds = new Set(this.skillIds.values())
      // if (this.skillIds.findIndex(skill.id) > -1)
      //   this.skillIds.splice(this.skillIds.findIndex(skill.id), 1);
      this.$set(skill, 'added', false)
      // console.log(this.skillIds, skill);
    },
    viewSkill(skill) {
      this.viewingSkill = skill
      console.log(skill)
    },
    displaySummary(skill) {
      return skill.metaData?.skillMeta?.summary || 'No summary'
    },
    isPremium(skill) {
      for (let tag of skill.metaData?.tagsList || [
        'skills/sample_assessment',
        'purpose/sample',
      ]) {
        if (tag === 'purpose/sample' || tag === 'skills/sample_assessment') {
          return false
        }
      }
      return true
    },
    async showPlanLimited() {
      if (
        await this.$refs.unlock.open(
          'To UNLOCK this feature, become a PREMIUM member today!',
        )
      ) {
        console.log('YEP')
      } else {
      }
    },
  },
  computed: {
    ...mapState('recruiter', ['assessment', 'createdAssessment']),
    ...mapState('customer', ['customer']),
    ...mapGetters('customer', ['hiringPlanKeys', 'prettyHiringPlans']),
    ...mapGetters('recruiter', ['cheatingSettings', 'cheatingSettingsValues']),
    hasDeadline() {
      return this.timeUnits.some((unit) => unit.value > 0)
    },

    autoRejectMessage() {
      if (this.sendEmail)
        return (
          'Candidates with a score less than ' +
          this.minScore +
          ' will be automatically rejected and an email will be send.'
        )
      else
        return (
          'Candidates with a score less than ' +
          this.minScore +
          ' will be automatically rejected.'
        )
    },
    minScoreRule() {
      return (value) => {
        if (!value) {
          return 'Minimum score is required'
        } else if (value < 0 || value > 100) {
          return 'Minimum score must be between 0 and 100'
        } else {
          return true
        }
      }
    },
    filteredSkills() {
      return this.skills.filter(
        (skill) =>
          skill.title.toLowerCase().includes(this.skillSearch.toLowerCase()) ||
          skill.metaData?.skillMeta?.summary
            .toLowerCase()
            .includes(this.skillSearch.toLowerCase()),
      )
    },
    extCopy() {
      return this.antiCheat.includes(1)
    },
    makeFullscreen() {
      return this.antiCheat.includes(2)
    },
    tabLog() {
      return this.antiCheat.includes(3)
    },
    videoRecording() {
      return this.antiCheat.includes(5)
    },
    hiringPlan() {
      return this.prettyHiringPlans[
        this.hiringPlanKeys[this.customer?.getHiringPlan()]
      ]
    },
  },
  created() {
    console.log('created ......')
    this.listSkills({
      userId: this.$store.getters['user/user'].uid,
    }).then((r) => {
      const x = r?.toObject()?.contentsList
      if (x.length > 0) {
        this.skills = x.filter((l) => l.contentType === 7)
        this.skillDlg = this.skills.map((_) => false)
      }
    })
    console.log('CUSTOMER', this.customer)
  },
  watch: {
    antiCheat: function (n, o) {
      var videoRecordingKey =
        this.cheatingSettingsValues['ANTI_CHEATING_VIDEO_RECORDING']
      if (
        n.includes(videoRecordingKey) &&
        !o.includes(videoRecordingKey) &&
        this.hiringPlan == 'Free'
      ) {
        this.showPlanLimited()
        this.antiCheat = this.antiCheat.filter((x) => x !== videoRecordingKey)
      }
    },
    skillIds: function (n, o) {
      this.descTasks = []
      this.skillTags = []
      this.totalTimeInS = 0
      this.skillIds.forEach((id) => {
        this.descTasks.push(this.skills.find((sk) => sk.id === id))
        this.totalTimeInS += this.skills.find(
          (sk) => sk.id === id,
        ).metaData?.timeLimit
        console.log(`totalTimeInS`, this.totalTimeInS)
      })
      this.skillIds.forEach((id) => {
        this.skillTags.push(this.skills.find((sk) => sk.id === id))
      })
    },
  },
}
</script>
<style scoped>
.v-stepper__header {
  box-shadow: none;
}
.skill-description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #333333;
}
</style>
