var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "overflow-y": "scroll"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "placeholder": "Search Skills",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.skillSearch,
      callback: function ($$v) {
        _vm.skillSearch = $$v;
      },
      expression: "skillSearch"
    }
  })], 1)], 1), _c('v-row', _vm._l(_vm.skillTags, function (tag) {
    return _c('v-chip', {
      key: tag.id,
      staticClass: "mx-2",
      attrs: {
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.rmSkillFromTagList(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.title) + " ")]);
  }), 1), _c('v-row', _vm._l(_vm.filteredSkills, function (skill, idx) {
    var _skill$metaData, _skill$metaData$skill, _skill$metaData2, _skill$metaData2$skil, _skill$metaData3, _vm$viewingSkill;
    return _c('v-col', {
      key: skill === null || skill === void 0 ? void 0 : skill.id,
      staticClass: "pa-2 ma-0",
      attrs: {
        "cols": "10",
        "sm": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "color": "#c4c4c435",
        "elevation": "0",
        "outlined": "",
        "height": "100%"
      }
    }, [_c('v-card-title', {
      staticClass: "d-flex flex-row justify-space-between text-subtitle-1"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [skill !== null && skill !== void 0 && (_skill$metaData = skill.metaData) !== null && _skill$metaData !== void 0 && (_skill$metaData$skill = _skill$metaData.skillMeta) !== null && _skill$metaData$skill !== void 0 && _skill$metaData$skill.imageUrl ? _c('v-img', {
      attrs: {
        "src": skill === null || skill === void 0 ? void 0 : (_skill$metaData2 = skill.metaData) === null || _skill$metaData2 === void 0 ? void 0 : (_skill$metaData2$skil = _skill$metaData2.skillMeta) === null || _skill$metaData2$skil === void 0 ? void 0 : _skill$metaData2$skil.imageUrl,
        "width": "50",
        "height": "50",
        "contain": ""
      }
    }) : _c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiFormatListChecks) + " ")])], 1), _c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('span', {
      staticClass: "font-weight-bold d-inline-flex"
    }, [_vm._v(" " + _vm._s(skill.title) + " ")])]), _c('v-col', {
      staticClass: "pr-5",
      attrs: {
        "cols": "2"
      }
    }, [!_vm.isPremium(skill) || _vm.hiringPlan !== 'Free' ? _c('div', [_c('div', {
      staticClass: "align-right"
    }, [!(skill !== null && skill !== void 0 && skill.added) ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.addSkillToTest(skill);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")])], 1) : _vm._e(), skill !== null && skill !== void 0 && skill.added ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeSkillFromTest(skill);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiMinus) + " ")])], 1) : _vm._e()], 1)]) : _c('div', [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": _vm.showPlanLimited
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": "red"
      }
    }, [_vm._v(" " + _vm._s(_vm.mdiLockOutline) + " ")])], 1)], 1)])], 1)], 1), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.displaySummary(skill)) + " ")]), _c('v-card-actions', {
      staticClass: "justify-space-between"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiTimer) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.displayTime(skill === null || skill === void 0 ? void 0 : (_skill$metaData3 = skill.metaData) === null || _skill$metaData3 === void 0 ? void 0 : _skill$metaData3.timeLimit)) + " ")])], 1), _c('div', [_c('v-dialog', {
      key: skill.title,
      attrs: {
        "width": "95%"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('div', {
            staticClass: "d-flex"
          }, [_c('v-btn', _vm._g(_vm._b({
            staticClass: "text-none",
            attrs: {
              "text": ""
            },
            on: {
              "click": function ($event) {
                return _vm.viewSkill(skill);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiInformation) + " ")]), _vm._v(" Details ")], 1)], 1)];
        }
      }], null, true),
      model: {
        value: _vm.skillDlg[idx],
        callback: function ($$v) {
          _vm.$set(_vm.skillDlg, idx, $$v);
        },
        expression: "skillDlg[idx]"
      }
    }, [_vm.viewingSkill && ((_vm$viewingSkill = _vm.viewingSkill) === null || _vm$viewingSkill === void 0 ? void 0 : _vm$viewingSkill.id) === skill.id ? _c('SkillModal', {
      attrs: {
        "skill": _vm.viewingSkill
      },
      on: {
        "closemodal": function ($event) {
          return _vm.closeSkillModal(idx);
        }
      }
    }) : _vm._e()], 1)], 1)])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }