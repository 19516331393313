<template>
  <v-card class="pa-5" elevation="0">
    <v-card-text style="overflow-y: scroll">
      <!-- skills section -->
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="skillSearch"
            placeholder="Search Skills"
            outlined
            dense
            class="mt-2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-chip
          close
          v-for="tag in skillTags"
          :key="tag.id"
          @click:close="rmSkillFromTagList(tag)"
          class="mx-2"
        >
          {{ tag.title }}
        </v-chip>
      </v-row>
      <v-row>
        <v-col
          v-for="(skill, idx) in filteredSkills"
          :key="skill?.id"
          class="pa-2 ma-0"
          cols="10"
          sm="6"
          md="4"
        >
          <v-card color="#c4c4c435" elevation="0" outlined height="100%">
            <v-card-title
              class="d-flex flex-row justify-space-between text-subtitle-1"
            >
              <v-row>
                <!-- Display skill icon/image -->
                <v-col cols="2">
                  <v-img
                    v-if="skill?.metaData?.skillMeta?.imageUrl"
                    :src="skill?.metaData?.skillMeta?.imageUrl"
                    width="50"
                    height="50"
                    contain
                  ></v-img>
                  <v-icon v-else>
                    {{ mdiFormatListChecks }}
                  </v-icon>
                </v-col>
                <v-col cols="8">
                  <span class="font-weight-bold d-inline-flex">
                    {{ skill.title }}
                  </span>
                </v-col>
                <v-col cols="2" class="pr-5">
                  <div v-if="!isPremium(skill) || hiringPlan !== 'Free'">
                    <div class="align-right">
                      <v-btn
                        v-if="!skill?.added"
                        @click="addSkillToTest(skill)"
                        icon
                      >
                        <v-icon large color="primary">
                          {{ mdiPlus }}
                        </v-icon>
                      </v-btn>

                      <v-btn
                        v-if="skill?.added"
                        @click="removeSkillFromTest(skill)"
                        icon
                      >
                        <v-icon large color="primary">
                          {{ mdiMinus }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <v-btn @click="showPlanLimited" icon>
                      <v-icon large color="red">
                        {{ mdiLockOutline }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              {{ displaySummary(skill) }}
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <div class="d-flex">
                <v-icon>
                  {{ mdiTimer }}
                </v-icon>
                <div>
                  {{ displayTime(skill?.metaData?.timeLimit) }}
                </div>
              </div>
              <div>
                <v-dialog
                  :key="skill.title"
                  v-model="skillDlg[idx]"
                  width="95%"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex">
                      <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="text-none"
                        @click="viewSkill(skill)"
                      >
                        <v-icon>
                          {{ mdiInformation }}
                        </v-icon>
                        Details
                      </v-btn>
                    </div>
                  </template>
                  <SkillModal
                    v-if="viewingSkill && viewingSkill?.id === skill.id"
                    :skill="viewingSkill"
                    v-on:closemodal="closeSkillModal(idx)"
                  />
                </v-dialog>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import SkillModal from '@/components/recruiter/SkillModal.vue'
import formattedTimeMixin from '@/mixins/formattedTimeMixin'

import {
  mdiPlus,
  mdiMinus,
  mdiTimer,
  mdiInformation,
  mdiFormatListChecks,
} from '@mdi/js'

export default {
  name: 'Skills',
  components: {
    SkillModal,
  },
  props: {
    skills: {
      type: Array,
      default: () => [],
    },
    skillTags: {
      type: Array,
      required: true,
    },
    hiringPlan: {
      type: String,
      default: '',
    },
  },
  mixins: [formattedTimeMixin],
  data() {
    return {
      skillSearch: '',
      skillDlg: [],
      viewingSkill: null,
      mdiPlus,
      mdiMinus,
      mdiTimer,
      mdiInformation,
      mdiFormatListChecks,
    }
  },
  computed: {
    ...mapState('recruiter', ['customerTestsLoading']),
    filteredSkills() {
      return this.skills.filter(
        (skill) =>
          skill.title.toLowerCase().includes(this.skillSearch.toLowerCase()) ||
          skill.metaData?.skillMeta?.summary
            .toLowerCase()
            .includes(this.skillSearch.toLowerCase()),
      )
    },
  },
  methods: {
    displaySummary(skill) {
      console.log('SKILL', skill)
      return skill?.metaData?.skillMeta?.summary || ''
    },
    isPremium(skill) {
      for (let tag of skill.metaData?.tagsList || [
        'skills/sample_assessment',
        'purpose/sample',
      ]) {
        if (tag === 'purpose/sample' || tag === 'skills/sample_assessment') {
          return false
        }
      }
      return true
    },
    addSkillToTest(skill) {
      console.log('addSkillToTest..', skill)
      this.$emit('addskill', skill)
    },
    removeSkillFromTest(skill) {
      this.$emit('removeskill', skill)
    },
    rmSkillFromTagList(tag) {
      this.$emit('removeskill', tag)
    },
    viewSkill(skill) {
      this.viewingSkill = skill
    },
    closeSkillModal(idx) {
      this.$set(this.skillDlg, idx, false)
    },
    showPlanLimited() {
      this.$emit('showplanlimited')
    },
  },
}
</script>
